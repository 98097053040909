import { resolver } from "./index";

export interface FacebookAdsPayload {
  accountId: string;
  storeId?: string;
  from: string;
  to: string;
  prevStartDate?: string;
  prevEndDate?: string;
}

interface FacebookAdsCardsPayload extends FacebookAdsPayload {
  cards: number[];
}

const getQuery = (endpointPath: string, payload: FacebookAdsCardsPayload) => {
  const { accountId, storeId, cards, from, to, prevStartDate, prevEndDate } = payload;

  let str = `${endpointPath}?storeId=${storeId}&accountId=${accountId}&startDate=${from}&endDate=${to}&cards=${String(
    cards,
  )}`;
  if (prevStartDate && prevEndDate)
    str += `&prevStartDate=${prevStartDate}&prevEndDate=${prevEndDate}`;

  return str;
};

export enum FBEndpointKeys {
  accounts = "accounts",
  facebookAdsData = "facebookAdsData",
  facebookAdsCards = "facebookAdsCards",
  facebookAdsAdsPlacement = "facebookAdsAdsPlacement",
  facebookAdsCharts = "facebookAdsCharts",
  facebookAdsTable = "facebookAdsTable",
}

const ENDPOINTS = {
  [FBEndpointKeys.accounts]: (storeId?: string) => `/api/facebook-ads/accounts?storeId=${storeId}`,
  [FBEndpointKeys.facebookAdsData]: (payload: FacebookAdsCardsPayload) =>
    getQuery("/api/facebook-ads", payload),
  [FBEndpointKeys.facebookAdsCards]: (payload: FacebookAdsCardsPayload) =>
    getQuery("/api/facebook-ads/cards", payload),
  [FBEndpointKeys.facebookAdsCharts]: (payload: FacebookAdsCardsPayload) =>
    getQuery("/api/facebook-ads/charts", payload),
  [FBEndpointKeys.facebookAdsAdsPlacement]: (payload: FacebookAdsCardsPayload) =>
    getQuery("/api/facebook-ads/ads-placements", payload),
  [FBEndpointKeys.facebookAdsTable]: (payload: FacebookAdsCardsPayload) =>
    getQuery("/api/facebook-ads/table", payload),
};

function getAccounts() {
  return resolver(ENDPOINTS.accounts());
}

export { ENDPOINTS, getAccounts };
