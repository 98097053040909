import { FBAction, PinnedCard } from "../../types";

export enum FBAdsCards {
  roas,
  conversions,
  spend,
  costPerConversion,
  costPerClick,
  CPM,
  conversionValue,
  CTR,
  avgOrderValue,
  revenuePerLinkClick,
  ctrLink,
  cpcLink,
  purchases,
  outboundClicks,
  linkClicks,
}

type OutBoundClick = {
  action_type: "outbound_click";
  value: string;
};

export type APIFBCardsResponseItem = {
  spend?: string;
  cpc?: string; //cost per click
  ctr?: string;
  actions?: FBAction[];
  action_values?: FBAction[];
  purchase_roas?: [
    {
      action_type: "omni_purchase";
      value: string;
    },
  ];
  cpm?: string;
  clicks?: string;
  date_start: string;
  outbound_clicks_ctr?: OutBoundClick[];
  cost_per_outbound_click?: OutBoundClick[];
  outbound_clicks?: OutBoundClick[];
};

export type APIFBCardsResponse = {
  data: APIFBCardsResponseItem[];
  summary?: APIFBCardsResponseItem;
};

export type FBCardsData = Record<keyof typeof FBAdsCards, PinnedCard<FBAdsCards>>;
