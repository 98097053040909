import React, { useCallback, useState } from "react";
import { SelectChangeEvent } from "@material-ui/core";
import { SelectItem } from "../../../src/components/Select/types";

export type GroupByOption = "day" | "week" | "month";

export const groupByOptions: SelectItem<GroupByOption>[] = [
  { id: "day", title: "Day" },
  { id: "week", title: "Week" },
  { id: "month", title: "Month" },
];

export type UseGroupBy = {
  groupByValue: GroupByOption;
  onGroupByChange: (e: SelectChangeEvent<GroupByOption>) => void;
  setGroupByValue: React.Dispatch<React.SetStateAction<GroupByOption>>;
};

export function useGroupBy(): UseGroupBy {
  const [groupByValue, setGroupByValue] = useState<GroupByOption>(groupByOptions[0].id);

  const onGroupByChange = useCallback((e: SelectChangeEvent<GroupByOption>) => {
    e.stopPropagation();
    setGroupByValue(e.target.value as GroupByOption);
  }, []);

  return {
    groupByValue,
    onGroupByChange,
    setGroupByValue,
  };
}
