export type LTVCardsRequestParams = {
  from: string;
  to: string;
  prevStartDate?: string;
  prevEndDate?: string;
  storeId: string | undefined;
  customerId: string | undefined;
  accountId: string | undefined;
  managerCustomerId: string | undefined;
  advertiserId?: number | undefined;
};

const ENDPOINTS = {
  ltvCards: {
    dataForPeriod: ({
      from,
      to,
      prevStartDate,
      prevEndDate,
      storeId,
      customerId,
      accountId,
      managerCustomerId,
      advertiserId,
    }: LTVCardsRequestParams) =>
      `/api/ltv?storeId=${storeId}&managerCustomerId=${managerCustomerId}&customerId=${customerId}&accountId=${accountId}&startDate=${from}&endDate=${to}&prevStartDate=${prevStartDate}&prevEndDate=${prevEndDate}&advertiserId=${advertiserId}`,
  },
  ltvTable: {
    dataForPeriod: ({
      storeId,
      from,
      to,
      prevStartDate,
      prevEndDate,
      page,
    }: {
      storeId: string | undefined;
      from: string;
      to: string;
      prevStartDate?: string;
      prevEndDate?: string;
      page: number;
    }) =>
      `/api/ltv/table?storeId=${storeId}&startDate=${from}&endDate=${to}&prevStartDate=${prevStartDate}&prevEndDate=${prevEndDate}&page=${page}`,
  },
};

export { ENDPOINTS };
