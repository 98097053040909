import { GroupByOption } from "../../context/DateDropdownContext";

export type StoreOverviewCardsRequestParams = {
  from: string;
  to: string;
  prevStartDate?: string;
  prevEndDate?: string;
  storeId: string | undefined;
  customerId: string | undefined;
  accountId: string | undefined;
  managerCustomerId: string | undefined;
  groupBy?: GroupByOption;
};

const getEndpointParams = ({
  from,
  to,
  prevStartDate,
  prevEndDate,
  storeId,
  customerId,
  accountId,
  managerCustomerId,
  groupBy,
}: StoreOverviewCardsRequestParams) => {
  let params = `?storeId=${storeId}&managerCustomerId=${managerCustomerId}&customerId=${customerId}&accountId=${accountId}&startDate=${from}&endDate=${to}&groupBy=${groupBy}`;

  if (prevStartDate && prevEndDate) {
    params = params.concat(`&prevStartDate=${prevStartDate}&prevEndDate=${prevEndDate}`);
  }

  return params;
};

const ENDPOINTS = {
  overviewCards: {
    dataForPeriod: (payload: StoreOverviewCardsRequestParams) =>
      `/api/store/overview${getEndpointParams(payload)}`,
  },
  spotlight: {
    dataForPeriod: (payload: StoreOverviewCardsRequestParams) =>
      `/api/store/spot-light${getEndpointParams(payload)}`,
  },
};

export { ENDPOINTS };
