import { Pages } from "../../src/types/Pages";
import { Card, GSCCardsKeys, GoogleAdsCards, ReChargeCards } from "../../types";
import { FBAdsCards } from "../facebook/types";
import { GA4CardsKeys } from "../google/ga4/types";
import { KlaviyoCardsKeys } from "../klaviyo/types";

export type PinnedService = Exclude<Pages, "googleAnalytics" | "finance" | "seo">;

export type FacebookPinnedCards = {
  [key in FBAdsCards]: Card;
};

export type GooglePinnedCards = {
  [key in GoogleAdsCards]: Card;
};

export type ConversionsPinnedCards = {
  [key in ConversionsCards]: Card;
};

export type StoreOverviewPinnedCards = {
  [key in StoreOverviewCards]: Card;
};

export type GA4PinnedCards = {
  [key in GA4CardsKeys]: Card;
};

export type GoogleSearchConsolePinnedCards = {
  [key in GSCCardsKeys]: Card;
};

export type KlaviyoPinnedCards = {
  [key in KlaviyoCardsKeys]: Card;
};

export type ReChargePinnedCards = {
  [key in ReChargeCards]: Card;
};

export type LtvPinnedCards = {
  [key in LtvCards]: Card;
};

export type TikTokPinnedCards = {
  [key in TikTokCards]: Card;
};

export type PinnedCards = {
  fbAdsPinnedCards: FacebookPinnedCards | undefined | { error: string };
  googleAdsPinnedCards: GooglePinnedCards | undefined | { error: string };
  storeOverviewPinnedCards: StoreOverviewPinnedCards | undefined | { error: string };
  conversionsPinnedCards: ConversionsPinnedCards | undefined | { error: string };
  ga4PinnedCards: GA4PinnedCards | undefined | { error: string };
  googleSearchConsolePinnedCards: GoogleSearchConsolePinnedCards | undefined | { error: string };
  klaviyoPinnedCards: KlaviyoPinnedCards | undefined | { error: string };
  LtvPinnedCards: LtvPinnedCards | undefined;
  tikTokPinnedCards: TikTokPinnedCards | undefined;
  reChargePinnedCards: ReChargePinnedCards | undefined | { error: string };
};

export type PinnedCardsServerResponse = {
  fbAdsPinnedCards: FBAdsCards[] | undefined;
  googleAdsPinnedCards: GoogleAdsCards[] | undefined;
  storeOverviewPinnedCards: StoreOverviewCards[] | undefined;
  conversionsPinnedCards: ConversionsCards[] | undefined;
  GA4PinnedCards?: GA4CardsKeys[] | undefined;
  googleSearchConsolePinnedCards?: GSCCardsKeys[] | undefined;
  klaviyoPinnedCards?: KlaviyoCardsKeys[] | undefined;
  reChargePinnedCards?: ReChargeCards[] | undefined;
  LtvPinnedCards?: LtvCards[] | undefined;
  tikTokPinnedCards?: TikTokCards[] | undefined;
  pinnedCardsDisplayOrder?: PinnedCardOrderItem[] | undefined;
};

export enum StoreOverviewCards {
  totalSales,
  totalAdsSpend,
  shipping,
  otherExpenses,
  returns,
  COGS,
  labourRate,
  grossProfit,
  grossProfitMargin,
  netProfit,
  taxesCard,
  netSales,
  percentageCOGS,
  shippingCost,
  transactionFee,
  fulfillment,
  contributionMargin,
  contributionMarginPercentage,
  netProfitPercentage,
  marketingPercentage,
  MERCard,
  BepMarCard,
  BepRoasCard,
  ordersCard,
  newCustomerAOV,
  repeatCustomerAOV,
  grossProfitNewCustomer,
  averageNoOfItems,
  AOV,
  totalCustomers,
  newCustomer,
  repeatCustomer,
  newCustomerNetSales,
  repeatCustomerNetSales,
  repeatCustomerGrossProfit,
  opexPercentage,
  newCustomerContributionMargin,
  CPA,
  nCAC,
  aMER,
  TotalSalesMER,
  blendedCPA,
}

export enum ConversionsCards {
  spend,
  ROAS,
  cpa,
  conversionValue,
  conversionRate,
  aov,
  impressions,
  threeSecondVideo,
  thumbStop,
  clickToPurchaseRate,
  outBoundClicks,
}

export enum LtvCards {
  NetSales,
  NewCustomerNetSales,
  RepeatCustomerNetSales,
  NewCustomerPercentage,
  RepeatCustomerPercentage,
  ThirtyDaysLTV,
  SixtyDaysLTV,
  NinetyDaysLTV,
  OneTwentyDaysLTV,
  ThreeSixtyDaysLTV,
  ThirtyDaysRepurchase,
  SixtyDaysRepurchase,
  NinetyDaysRepurchase,
  OneTwentyDaysRepurchase,
  ThreeSixtyDaysRepurchase,
  PurchaseFrequency,
  LTVbyCAC,
  CAC,
  LTV,
}

export enum TikTokCards {
  conversions,
  conversionsValue,
  spend,
  costPerConversion,
  costPerClick,
  roas,
  purchase,
  purchaseValue,
}

export type PinnedCardOrderItem = {
  displayOrder: number;
  pinnedCardService: PinnedService;
  cardId:
    | FBAdsCards
    | GoogleAdsCards
    | StoreOverviewCards
    | ConversionsCards
    | GA4CardsKeys
    | GSCCardsKeys
    | KlaviyoCardsKeys
    | LtvCards
    | TikTokCards
    | ReChargeCards;
};
