import { CohortsGroupBy, CohortType } from "../../lib/cohorts/types";

export type CohortsRequestParams = {
  from: string;
  to: string;
  prevStartDate?: string;
  prevEndDate?: string;
  storeId: string | undefined;
  customerId: string | undefined;
  accountId: string | undefined;
  managerCustomerId: string | undefined;
  groupBy: CohortsGroupBy;
  type: CohortType;
};

const ENDPOINTS = {
  cohorts: {
    dataForPeriod: ({
      from,
      to,
      prevStartDate,
      prevEndDate,
      storeId,
      customerId,
      accountId,
      managerCustomerId,
      groupBy,
      type,
    }: CohortsRequestParams) =>
      `/api/cohorts?storeId=${storeId}&managerCustomerId=${managerCustomerId}&customerId=${customerId}&accountId=${accountId}&startDate=${from}&endDate=${to}&prevStartDate=${prevStartDate}&prevEndDate=${prevEndDate}&type=${type}&groupBy=${groupBy}`,
  },
};

export { ENDPOINTS };
