import { useRouter } from "next/router";
import { useLayoutEffect } from "react";
import { UserPermissions } from "../lib/db/types";
import { Store } from "../types";

const permissionsMapping: { [key: string]: UserPermissions } = {
  "/ads-overview": UserPermissions.ADS_OVERVIEW,
  "/store/overview": UserPermissions.STORE_OVERVIEW,
  "/finance": UserPermissions.FINANCE,
  "/products": UserPermissions.PRODUCTS,
  "/orders": UserPermissions.ORDERS,
  "/marketing/creatives/overview": UserPermissions.CREATIVE_OVERVIEW,
  "/marketing/facebook": UserPermissions.META_ADS,
  "/marketing/google": UserPermissions.GOOGLE_ADS,
  "/marketing/tiktok": UserPermissions.TIKTOK_ADS,
  "/marketing/recharge": UserPermissions.RECHARGE,
  "/marketing/klaviyo": UserPermissions.KLAVIYO,
  "/marketing/google-analytics-4": UserPermissions.GA4,
  "/marketing/google-analytics": UserPermissions.GOOGLE_ANALYTICS,
  "/marketing/seo": UserPermissions.SEO,
  "/settings/connections": UserPermissions.SETTINGS_CONNECTIONS,
  "/settings/team": UserPermissions.SETTINGS_TEAM,
  // "/settings/costs/products": UserPermissions.SETTINGS_COSTS_PRODUCTS, //handled in the component as it should be possible to access the page without the permission to see all other costs tabs
  "/settings/costs/staff": UserPermissions.SETTINGS_COSTS_STAFF_COSTS,
  "/settings/costs/shipping": UserPermissions.SETTINGS_COSTS_SHIPPING,
  "/settings/costs/fulfillment": UserPermissions.SETTINGS_COSTS_FULFILLMENT,
  "/settings/costs/transactionFees": UserPermissions.SETTINGS_COSTS_TRANSACTION_FEES,
  "/settings/costs/otherCosts": UserPermissions.SETTINGS_COSTS_OTHER_COSTS,
  "/settings/costs/defaults": UserPermissions.SETTINGS_COSTS_DEFAULTS,
};

export function usePermissions(selectedStore: Store | null) {
  const router = useRouter();
  const permissions = selectedStore?.permissions as string[];
  const path = router.asPath;

  useLayoutEffect(() => {
    if (!selectedStore) return;
    const requiredPermission = permissionsMapping[path];
    if (requiredPermission) {
      const hasPermissions = permissions?.includes(requiredPermission);
      if (!hasPermissions) {
        router.push("/dashboard");
      }
    }
  }, [path, permissions, router, selectedStore]);
}
