import type { AppProps } from "next/app";
import React, { useEffect } from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import { usePathname } from "next/navigation";
import { useRouter } from "next/router";
import Head from "next/head";
import { RutterConnectionsProvider } from "../context/RutterConnectionsContext";
import { useAuthContext } from "../context/AmplifyContext";
import { MixpanelTracking } from "./services/MixpanelService";
import { useWhitelabeling } from "./hooks/useWhitelabeling";
import { createCustomTheme } from "./theme";
import useSettings from "./hooks/useSettings";
import { useStoresContext } from "../context/StoresContext";
import { LoadingOverlay } from "./components/AppComponents/LoadingOverlay";
import useFetchWhitelabeling from "./hooks/useFetchWhitelabeling";
import useIsSwapAgency from "./hooks/useIsSwapAgency";

export const DEFAULT_NAVBAR_COLOR = "#5664D2";
export const DEFAULT_ACCENT_COLOR = "#5664D2";
const DEFAULT_NAVBAR_TEXT_COLOR = "#fff";

const nonAuthedPages = [
  "/login",
  "/onboarding",
  "/register",
  "/",
  "/login/recovery",
  "/signin",
  "/link-expired",
  "/dev/unlink-agency",
  "/dev/apply-whitelabeling",
];

export default function Pages({ Component, pageProps }: AppProps) {
  const { stores, isStoresLoading, selectedStore } = useStoresContext();
  const router = useRouter();
  const pathName = usePathname();
  const { settings } = useSettings();
  const { authedUser, userAttributes } = useAuthContext();
  const mixpanelService = MixpanelTracking.getInstance();
  const email = userAttributes?.email;
  const sub = userAttributes?.sub;
  const name = userAttributes?.name;

  // redirect user if process onboarding store unfinished
  useEffect(() => {
    if (
      !isStoresLoading &&
      authedUser &&
      selectedStore?.isOnboarding &&
      router.pathname !== "/settings/account"
    ) {
      router.replace("/onboarding");
    }
  }, [selectedStore]);

  React.useEffect(() => {
    if (email && sub) {
      mixpanelService.identifyNewUser(sub);
      mixpanelService.setUser(email, name || "");
    }
  }, [email, mixpanelService, name, sub]);

  const { data: whitelabelingData, logo } = useWhitelabeling();
  useFetchWhitelabeling();
  const isSwapAgency = useIsSwapAgency();

  React.useEffect(() => {
    const accentColor = whitelabelingData?.response?.colors?.accent || DEFAULT_ACCENT_COLOR;
    const navbarColor = whitelabelingData?.response?.colors?.navigation || DEFAULT_NAVBAR_COLOR;
    const navbarTextColor = isSwapAgency ? "#000" : DEFAULT_NAVBAR_TEXT_COLOR;

    document.documentElement.style.setProperty("--primary", accentColor);
    document.documentElement.style.setProperty("--navbar", navbarColor);
    document.documentElement.style.setProperty("--navbar-text", navbarTextColor);
    document.querySelector("meta[name='theme-color']")?.setAttribute("content", navbarColor);
  }, [
    isSwapAgency,
    whitelabelingData?.response?.colors?.accent,
    whitelabelingData?.response?.colors?.navigation,
  ]);

  const theme = createCustomTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme,
    primaryColor: whitelabelingData?.response?.colors?.accent || DEFAULT_ACCENT_COLOR,
  });

  React.useEffect(() => {
    if (
      stores.length === 0 &&
      !isStoresLoading &&
      authedUser &&
      !nonAuthedPages.includes(pathName) &&
      router.pathname !== "/settings/account"
    ) {
      router.replace("/onboarding");
    }
  }, [authedUser, isStoresLoading, pathName, router, stores.length]);

  if (
    !nonAuthedPages.includes(pathName) &&
    router.pathname !== "/settings/account" &&
    (isStoresLoading || !selectedStore || stores.length === 0)
  ) {
    return <LoadingOverlay fullPage />;
  }

  if (
    !nonAuthedPages.includes(pathName) &&
    router.pathname !== "/settings/account" &&
    (whitelabelingData.isLoading || !whitelabelingData.isSuccess)
  ) {
    return <LoadingOverlay fullPage />;
  }

  return (
    <ThemeProvider theme={theme}>
      <Head>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
        {!whitelabelingData.isLoading &&
          whitelabelingData.isSuccess &&
          !whitelabelingData.isLoadingPartnerId && (
            <link rel="icon" href={logo.smallSizeFavicon} type="image/png" />
          )}
      </Head>
      <RutterConnectionsProvider>
        <Component {...pageProps} />
      </RutterConnectionsProvider>
    </ThemeProvider>
  );
}
