import Link from "next/link";
import { Store } from "../../../types";
import { IActiveSalesChannel, IChecklistItem, IFormatOnboardingChecklistData } from "./types";
import { OnboardingChecklistItemManualOverride } from "../../../lib/db/onboarding-checklist";

export const activeSalesChannel = (selectedStore: Store): IActiveSalesChannel[] => [
  {
    label: "BigCommerce",
    isActive: selectedStore?.isBigCommerceStoreConnected,
  },
  {
    label: "Shopify",
    isActive: selectedStore?.isShopifyStoreConnected,
  },
  {
    label: "WooCommerce",
    isActive: selectedStore?.isWoocommerceStoreConnected,
  },
  {
    label: "Magento",
    isActive: selectedStore?.isMagentoStoreConnected,
  },
];

export const salesChannelTitle = (selectedStore: Store) =>
  (selectedStore &&
    activeSalesChannel(selectedStore).filter((item: IActiveSalesChannel) => item.isActive)[0]
      ?.label) ||
  "";

export const formatOnboardingChecklistData = ({
  areBasicsCompleted,
  areFulfillmentCostsCompleted,
  areProductCostsCompleted,
  areShippingCostsCompleted,
  areTransactionFeesCompleted,
  allMarkedAsComplete,
  salesChannel = "sales channel",
  shippingMethod = "Shipping Method",
  fulfilmentCostsSettings = "#",
  transactionFeesSettings = "#",
}: IFormatOnboardingChecklistData): IChecklistItem[] => [
  {
    id: OnboardingChecklistItemManualOverride.BasicCompleted,
    title: "Connect the Basics",
    activeDescription: `It looks like you've connected ${salesChannel} and an ads channel.`,
    inActiveDescription:
      "We recommend connecting your store and at least one paid advertising channel.",
    duration: "3 minutes",
    url: "https://google.com",
    isCompleted: allMarkedAsComplete || areBasicsCompleted,
  },
  {
    id: OnboardingChecklistItemManualOverride.ProductCostsCompleted,
    title: "Product Costs",
    activeDescription:
      "It looks like most of the products sold in the last 30 days used a product cost that you've defined.",
    inActiveDescription:
      "Most products sold in the last 30 days don't have a product cost associated with them.",
    duration: "3 minutes",
    url: "https://google.com",
    isCompleted: allMarkedAsComplete || areProductCostsCompleted,
  },
  {
    id: OnboardingChecklistItemManualOverride.ShippingCostsCompleted,
    title: "Shipping Costs",
    activeDescription: `We are currently calculating shipping costs by ${shippingMethod}.`,
    inActiveDescription: "Please set up a shipping calculation method.",
    duration: "3 minutes",
    url: "https://google.com",
    isCompleted: allMarkedAsComplete || areShippingCostsCompleted,
  },
  {
    id: OnboardingChecklistItemManualOverride.FulfillmentCostsCompleted,
    title: "Fulfilment Costs",
    activeDescription: `You've set a pick and pack fee and/or packaging costs, great!`,
    inActiveDescription: (
      <>
        Do you work with a third party logistics provider (3PL)? If so, please add their costs{" "}
        <Link href={`/${fulfilmentCostsSettings}`} target="_blank">
          here
        </Link>
      </>
    ),
    duration: "3 minutes",
    url: "https://google.com",
    isCompleted: allMarkedAsComplete || areFulfillmentCostsCompleted,
  },
  {
    id: OnboardingChecklistItemManualOverride.TransactionFeesCompleted,
    title: "Transaction Fees",
    activeDescription: `It looks like all orders in the last 30 days have a transaction fee assigned to them.`,
    inActiveDescription: (
      <>
        There are some orders that we&apos;ve found in the last 30 days that don&apos;t have a
        transaction fee. Please make sure to set all gateways{" "}
        <Link href={`/${transactionFeesSettings}`} target="_blank">
          here
        </Link>
      </>
    ),
    duration: "3 minutes",
    url: "https://google.com",
    isCompleted: allMarkedAsComplete || areTransactionFeesCompleted,
  },
];
