import { IExtendedFilter } from "../hooks/useFiltering";

const ENDPOINTS: any = {
  ordersData: ({
    storeId,
    from,
    to,
    currency,
    page,
    limit,
    sorting,
    search,
    filter,
    groupBy,
  }: {
    storeId: string;
    from: string;
    to: string;
    currency: string;
    page: string | number;
    limit: string | number;
    sorting: { attribute: string; property: string };
    search: string;
    filter: IExtendedFilter[];
    groupBy: string;
  }) => {
    const encodedFilter = encodeURIComponent(JSON.stringify(filter));
    return `/api/store/orders?storeId=${storeId}&startDate=${from}&endDate=${to}&currency=${currency}&page=${page}&limit=${limit}&attrib=${sorting?.attribute}&property=${sorting?.property}&searchById=${search}&filter=${encodedFilter}&groupBy=${groupBy}`;
  },
  exportOrders: ({ storeId, from, to }: { storeId: string; from: string; to: string }) =>
    `/api/store/orders/export?storeId=${storeId}&startDate=${from}&endDate=${to}`,
  summary: ({
    storeId,
    from,
    to,
    currency,
    search,
    filter,
  }: {
    storeId: string;
    from: string;
    to: string;
    currency: string;
    search: string;
    filter: IExtendedFilter[];
  }) => {
    const encodedFilter = encodeURIComponent(JSON.stringify(filter));
    return `/api/store/orders/summary?storeId=${storeId}&startDate=${from}&endDate=${to}&currency=${currency}&searchById=${search}&filter=${encodedFilter}`;
  },
};

export { ENDPOINTS };
